'use client';

// This must be a Client Component since it receives callbacks from other
// components.

import { classes } from '@/next-utils/css-utils/scss-utils';
import { InvalidArgumentError } from '@/utils/errors';
import { exhaustiveGuard } from '@/utils/function-utils';
import type { EventHandler, FC, SyntheticEvent } from 'react';
import type IStylable from '../../traits/IStylable';
import { IconAnimations } from './IconAnimations';
import { IconTypes, icons } from './IconTypes';

import S from './styles.module.scss';

/**
 * The size prop to be turned into the size class.
 */
export enum IconSizeProp {
  SizeXXS,
  SizeXS,
  SizeSM,
  Size1X,
  Size2X,
  Size3X,
  SizeHeavy,
  SizeLG,
  SizeXL
}

/**
 * Props for customizing the Icon Component.
 */
export interface IIconProps extends IStylable {
  /** The icon following the icons available in the IconTypes. */
  icon: IconTypes;

  /** Size of the Icon based on FontAwesome sizes. */
  size?: IconSizeProp;

  /**
   * The color of the Icon itself. This will override any color
   * set by the {@see IIconProps.style}.
   */
  color?: string;

  /** Title for the icon container if it is a button. */
  title?: string;

  /** Label for screen readers on the button. */
  ariaLabel?: string;

  /**
   * Click event.
   */
  onClick?: EventHandler<SyntheticEvent>;

  /** Key press event. */
  onKeyPress?: EventHandler<SyntheticEvent>;

  /** Animation enum of css classes. */
  animation?: IconAnimations;
}

/**
 * Gets the size class for the given size prop.
 * @param size - The passed in size prop using the `IconSizeProp` enum.
 * @returns - The class to be used on the icon wrapper.
 * @throws - If an invalid size is used, should not build.
 */
const getSizeClassFromProp = (size: IconSizeProp): string => {
  switch (size) {
    case IconSizeProp.SizeXXS:
      return S['size-xxs'];
    case IconSizeProp.SizeXS:
      return S['size-xs'];
    case IconSizeProp.SizeSM:
      return S['size-sm'];
    case IconSizeProp.Size1X:
      return S['size-1x'];
    case IconSizeProp.Size2X:
      return S['size-2x'];
    case IconSizeProp.Size3X:
      return S['size-3x'];
    case IconSizeProp.SizeHeavy:
      return S['size-heavy'];
    case IconSizeProp.SizeLG:
      return S['size-lg'];
    case IconSizeProp.SizeXL:
      return S['size-xl'];
    default:
      return exhaustiveGuard(
        size,
        new InvalidArgumentError(`Size ${size} is an invalid icon size.`)
      );
  }
};

/** A component for displaying icons from a predefined set. */
export const Icon: FC<IIconProps> = ({
  icon,
  size = IconSizeProp.Size1X,
  className,
  id,
  color,
  title,
  ariaLabel,
  style,
  onClick,
  onKeyPress,
  animation
}) => {
  const sharedClasses = classes(
    className,
    animation != null && S[animation],
    S.wrapper,
    getSizeClassFromProp(size)
  );

  style = { ...style, color: color ?? style?.color };

  return onClick ? (
    // Not using the `Button` component here
    // since it would cause a dependency cycle.
    <div
      id={id}
      role="button"
      tabIndex={0}
      title={title}
      aria-label={ariaLabel}
      style={style}
      onClick={onClick ?? undefined}
      onKeyPress={onKeyPress ?? onClick ?? undefined}
      className={classes(sharedClasses, S.interactive)}
    >
      {icons[icon]}
    </div>
  ) : (
    <div style={style} className={sharedClasses}>
      {icons[icon]}
    </div>
  );
};

export { IconAnimations } from './IconAnimations';
export { IconTypes } from './IconTypes';
